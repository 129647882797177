.app {
  height: 100%;
  min-height: 100%;
  h2 {
    color: #ec2031;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding-left: 5px;
    li {
      font-size: 20px;
      margin-bottom: 15px;
      .MuiChip-root {
        min-width: 80px !important;
        font-weight: bolder;
        background-color: white;
        float: right;
        clear: right;
      }
    }
  }
  .MuiChip-root {
    min-width: 80px !important;
    font-weight: bolder;
    background-color: white;
  }
  .logo {
    max-width: 100%;
    height: auto;
  }
  .header {
    background-color: #ec2031;
    text-align: center;
    padding-bottom: 20px;
  }
  .header-bottom {
    background-color: black;
    height: 60px;
  }
  .puertovallarta {
    margin-top: 110px;
  }
  .content {
    .search-component {
      text-align: center;
      .search-input {
        width: 100%;
      }
    }
  }
  .totals {
    text-align: center;
    .MuiChip-root {
      background-color: white;
      font-weight: bolder;
    }
  }
}
